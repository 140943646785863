<template>
  <div class="container-fluid">
    <div class="container">
      <div class="fascia">
        <div class="row">
          <div class="col-md-3 mt-4">
            <img src="./assets/img/logo_venicewatertaxi.png" class="logo"/>
          </div>
          <div class="col-md-6 mt-4">
            <p class="callcenter">
              <strong>CALL CENTER operativo 24 ore su 24</strong>
              <a href=""><b-icon icon="telephone-fill"></b-icon> +39 041 5229040</a>
            </p>
          </div>
          <div class="col-md-3 mt-4">

          </div>
        </div>
      </div>
      <div class="immagine">
        <div class="velo"></div>
        <strong>Acquista Biglietti Online</strong>
      </div>
      <div class="layout-page">
        <router-view />
      </div>
      <div class="footer">
        Consorzio VWT S. Croce, 464 - 30123 - Venezia (Italia) P.Iva: 04129060275 C.F.: 04129060275  / Tel. +39 041 5229040 - info@venicewatertaxi.it
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
export default {
  name: 'App',
  data() {
        return {
            logged:false
        };
  },
  mounted(){
    //this.checklogin()
  },
  watch:{
    $route (to, from){
      //this.checklogin()
    }
  },
  methods:{
    checklogin(){
    
    },
  },
  components: {
  }
}
</script>